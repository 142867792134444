<template>
  <div class="container">
    <b-row class="justify-content-center">
      <b-col md="6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">404</h1>
          <h4 class="pt-3">페이지를 찾을 수 없습니다</h4>
          <p class="text-muted">The page you are looking for was not found.</p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    name: 'Page404'
  }

</script>
